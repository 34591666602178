<template>
  <c-box
    :px="[3, 3, 5]"
    pt="5"
    pb="2"
    bg="#4267B211"
    borderRadius="5px"
    boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
    minHeight="200px"
    border="1px solid #dae7ec"
    style="max-width: 100%; overflow-wrap: break-word"
  >
    <c-modal
      :is-open="isImagePreviewModalOpen"
      :on-close="closeImagePreviewModal"
      :is-centered="true"
      size="6xl"
      v-if="post.attachments.length"
      class="fixed inset-0 z-50 flex items-center justify-center"
    >
      <c-modal-overlay class="rounded-xl" />
      <c-modal-content
        class="relative bg-transparent backdrop-blur-md rounded-2xl shadow-lg p-4 rounded-xl"
      >
        <c-modal-body class="w-full h-[600px] bg-black flex flex-col relative">
          <!-- Close Button -->
          <!-- <c-modal-close-button
            @click="closeImagePreviewModal"
            class="absolute top-4 right-4 text-gray-600 text-2xl z-20 cursor-pointer"
          /> -->

          <!-- Image Viewer -->
          <c-box class="slider flex-grow relative flex items-center justify-center">
            <!-- Previous Button -->
            <c-icon
              v-if="post.attachments.length > 1"
              size="38px"
              bg="black"
              name="chevron-left"
              class="absolute rounded-xl left-4 p-2 top-1/2 transform -translate-y-1/2 text-white cursor-pointer z-10"
              @click.native="prevSlide"
            />

            <!-- Image Content -->
            <c-box v-if="post.attachments[currentIndex].type.startsWith('image')" style="height: 700px" class="slider-content flex items-center justify-center">
              <c-image
                class="rounded-xl"
                objectFit="contain"
                style="height: 100%"
                :src="post.attachments[currentIndex].url"
                alt="Image"
              />
            </c-box>

            <c-box v-if="post.attachments[currentIndex].type.startsWith('video')" style="height: 700px" class="slider-content flex items-center justify-center">
              <video
                controls
                class="video"
                :data-src="post.attachments[currentIndex].url"
                v-lazy-load-video
              />
            </c-box>

            <!-- Next Button -->
            <c-icon
              v-if="post.attachments.length > 1"
              size="38px"
              name="chevron-right"
              bg="black"
              class="absolute right-4 rounded-xl top-1/2 p-2 transform -translate-y-1/2 text-white cursor-pointer z-10"
              @click.native="nextSlide"
            />
          </c-box>

          <!-- Footer (Optional for text or interactions) -->
          <c-box class="mt-4 text-center text-sm text-gray-600">
            {{ post.attachments[currentIndex].caption || '' }}
          </c-box>
        </c-modal-body>
      </c-modal-content>
    </c-modal>

    <c-modal
      :is-open="isImgPreviewModalOpen"
      :on-close="closeImgPreviewModal"
      :is-centered="true"
      size="6xl"
      v-if="imageSources"
      class="fixed inset-0 z-50 flex items-center justify-center"
    >
      <c-modal-overlay class="rounded-xl" />
      <c-modal-content
        class="relative bg-transparent backdrop-blur-md rounded-2xl shadow-lg p-4 rounded-xl"
      >
        <c-modal-body class="w-full h-[600px] bg-black flex flex-col relative">
          <!-- Close Button -->
          <!-- <c-modal-close-button
            @click="closeImagePreviewModal"
            class="absolute top-4 right-4 text-gray-600 text-2xl z-20 cursor-pointer"
          /> -->

          <!-- Image Viewer -->
          <c-box class="slider flex-grow relative flex items-center justify-center">
            <!-- Previous Button -->
            <c-icon
              v-if="imageSources.length > 1"
              size="38px"
              bg="black"
              name="chevron-left"
              class="absolute rounded-xl left-4 p-2 top-1/2 transform -translate-y-1/2 text-white cursor-pointer z-10"
              @click.native="prevSlide1"
            />

            <!-- Image Content -->
            <c-box style="height: 700px" class="slider-content flex items-center justify-center">
              <c-image
                class="rounded-xl"
                objectFit="contain"
                style="height: 100%"
                :src="imageSources[currentIndex1]"
                alt="Image"
              />
            </c-box>

            <!-- Next Button -->
            <c-icon
              v-if="imageSources.length > 1"
              size="38px"
              name="chevron-right"
              bg="black"
              class="absolute right-4 rounded-xl top-1/2 p-2 transform -translate-y-1/2 text-white cursor-pointer z-10"
              @click.native="nextSlide1"
            />
          </c-box>

          <!-- Footer (Optional for text or interactions) -->
          <c-box class="mt-4 text-center text-sm text-gray-600">
            {{ '' }}
          </c-box>
        </c-modal-body>
      </c-modal-content>
    </c-modal>

    <c-flex align="center" justify="space-between" mb="6" v-if="!hideActions">
      <c-flex pr="3" align="center">
        <c-image
          cursor="pointer"
          @click="goToProfile"
          w="45px"
          h="45px"
          borderRadius="8px"
          :name="
            post.isCompanyPost
              ? post.company.name
              : post.user.firstname + ' ' + post.user.lastname
          "
          :src="
            post.isCompanyPost
              ? post.company.logo ?? require('@/assets/img/company.png')
              : post.user.profilePhoto ?? require('@/assets/img/user.jpg')
          "
          borderWidth="0px"
          mr="2"
        />
        <div>
          <div
            style="
              display: inline-block;
              word-break: break-word;
              overflow-wrap: break-word;
              font-weight: 600;
              cursor: pointer;
              font-size: 15px;
              margin-right: 5px;
            "
          >
            <template v-if="post.isCompanyPost">
              <span
                style="white-space: nowrap; margin-right: 3px"
                @click="goToProfile"
              >
                {{ post.company.name }}
                <span v-if="post.post_feeling"> {{ post.post_feeling }}</span>
                {{
                  post.mentions && post.mentions.length
                    ? post.post_feeling
                      ? 'with'
                      : 'is with'
                    : ''
                }}
              </span>
              <span
                @click="goToMentionProfile(mention)"
                style="
                  color: orangered;

                  word-break: break-word;
                  overflow-wrap: break-word;
                "
                :key="mention.id"
                v-for="(mention, index) in post.mentions"
              >
                {{
                  mention.company
                    ? mention.company.name
                    : mention.user.firstname + ' ' + mention.user.lastname
                }}
                <span style="color: black; margin-right: 3px">{{
                  post.mentions.length > 1
                    ? index === post.mentions.length - 2
                      ? 'and'
                      : index === post.mentions.length - 1
                      ? ''
                      : ','
                    : ''
                }}</span>
              </span>
            </template>
            <template v-else>
              <span
                class="mr-2"
                style="white-space: nowrap; margin-right: 3px"
                @click="goToProfile"
                >{{ post.user.firstname }} {{ post.user.lastname }}
                {{
                  post.mentions && post.mentions.length ? 'is with' : ''
                }}</span
              >
              <span v-if="post.post_feeling"> {{ post.post_feeling }}</span>
              <span
                @click="goToMentionProfile(mention)"
                style="
                  color: orangered;
                  display: inline-block;
                  word-break: break-word;
                  overflow-wrap: break-word;
                "
                :key="mention.id"
                v-for="(mention, index) in post.mentions"
              >
                {{
                  mention.company
                    ? mention.company.name
                    : mention.user.firstname + ' ' + mention.user.lastname
                }}
                <span style="color: black; margin-right: 3px">{{
                  post.mentions.length > 1
                    ? index === post.mentions.length - 2
                      ? 'and'
                      : index === post.mentions.length - 1
                      ? ''
                      : ','
                    : ''
                }}</span>
              </span>
            </template>
          </div>
          <c-text fontWeight="500" fontSize="xs" color="gray.400">
            {{ post.created_at | moment('from') }}
          </c-text>
        </div>
      </c-flex>
      <c-flex align="center">
        <c-text
          color="grey"
          mr="3"
          fontWeight="500"
          v-if="post.isEdited"
          fontSize="xs"
          >edited</c-text
        >
        <div style="display: flex; align-items: center">
          <c-link v-if="!isSavedPost" @click="onSaveClick">
            <svg
              v-chakra="{
                w: '24px',
                h: '24px',
              }"
              v-if="post.post_saves.length"
            >
              <use href="@/assets/icons/icon-star-filled.svg#star"></use>
            </svg>
            <svg
              v-chakra="{
                fill: 'vc-orange.400',
                w: '24px',
                h: '24px',
              }"
              v-else
            >
              <use href="@/assets/icons/icon-star.svg#star"></use>
            </svg>
          </c-link>
          <c-popover :close-on-blur="true" placement="right" v-if="!isMyPost">
            <c-popover-trigger>
              <c-link ml="4">
                <c-icon color="gray.500" name="ellipsis-h" />
              </c-link>
            </c-popover-trigger>
            <c-popover-content w="max-content">
              <c-popover-body p="0">
                <c-list>
                  <c-list-item v-if="isSavedPost">
                    <c-pseudo-box
                      fontSize="sm"
                      cursor="pointer"
                      @click="onRemoveClick"
                      p="3"
                    >
                      <c-flex align="center">
                        <c-icon color="vc-orange.300" name="close" />
                        <c-text ml="3">Remove from list</c-text>
                      </c-flex>
                    </c-pseudo-box>
                  </c-list-item>
                  <c-list-item v-else>
                    <c-pseudo-box
                      fontSize="sm"
                      cursor="pointer"
                      @click="onSaveClick"
                      p="3"
                    >
                      <c-flex align="center">
                        <c-icon v-if="post.post_saves.length" color="vc-orange.300" name="close" />
                        <c-icon v-else color="vc-orange.300" name="bookmark" />
                        <c-text v-if="post.post_saves.length" ml="3">Remove from saved list</c-text>
                        <c-text v-else ml="3">Save this Post</c-text>
                      </c-flex>
                    </c-pseudo-box>
                  </c-list-item>

                  <c-list-item v-if="(isCompanyAdmin || isCompanyOwner) && (activeCompany.id === post.companyId) && post.isCompanyPost">
                    <c-pseudo-box
                      fontSize="sm"
                      cursor="pointer"
                      @click="startPostEdit"
                      p="3"
                    >
                      <c-flex align="center">
                        <c-icon color="vc-orange.300" name="pen" />
                        <c-text ml="3">Edit Post</c-text>
                      </c-flex>
                    </c-pseudo-box>
                  </c-list-item>

                  <c-list-item v-if="(isCompanyAdmin || isCompanyOwner) && (activeCompany.id === post.companyId) && post.isCompanyPost">
                    <c-pseudo-box
                      fontSize="sm"
                      cursor="pointer"
                      @click="confirmPostDelete"
                      p="3"
                    >
                      <c-flex align="center">
                        <c-icon color="vc-orange.300" name="trash" />
                        <c-text ml="3">Delete Post</c-text>
                      </c-flex>
                    </c-pseudo-box>
                  </c-list-item>

                  <c-list-item>
                    <c-pseudo-box
                      fontSize="sm"
                      cursor="pointer"
                      @click="Unfollow(index)"
                      p="3"
                    >
                      <c-flex align="center">
                        <c-icon color="vc-orange.300" name="close" />
                        <c-text ml="3">Unfollow</c-text>
                      </c-flex>
                    </c-pseudo-box>
                  </c-list-item>

                  <c-list-item>
                    <c-pseudo-box
                      fontSize="sm"
                      cursor="pointer"
                      @click="getShareLink"
                      p="3"
                    >
                      <c-flex align="center">
                        <c-icon color="vc-orange.300" name="link" />
                        <c-text ml="3">Copy link to post</c-text>
                      </c-flex>
                    </c-pseudo-box>
                  </c-list-item>
                </c-list>
              </c-popover-body>
            </c-popover-content>
          </c-popover>
          <c-popover :close-on-blur="true" placement="bottom" v-if="isMyPost">
            <c-popover-trigger>
              <c-link ml="4">
                <svg
                  v-chakra="{
                    width: '10px',
                    height: '16px',
                    fill: '#ef5323',
                  }"
                >
                  <use href="@/assets/icons/vertical-dots.svg#dots"></use>
                </svg>
              </c-link>
            </c-popover-trigger>
            <c-popover-content style="z-index: 5" w="max-content">
              <c-popover-body p="0">
                <c-list>
                  <c-list-item v-if="!isPostDetailPage">
                    <c-pseudo-box
                      fontSize="sm"
                      cursor="pointer"
                      @click="
                        $router.push({
                          name: 'ViewInsight',
                          params: { id: post.id },
                        })
                      "
                      p="3"
                    >
                      <c-flex align="center">
                        <c-icon color="vc-orange.300" name="eye" />
                        <c-text ml="3">View this Post</c-text>
                      </c-flex>
                    </c-pseudo-box>
                  </c-list-item>

                  <c-list-item v-if="isSavedPost">
                    <c-pseudo-box
                      fontSize="sm"
                      cursor="pointer"
                      @click="onRemoveClick"
                      p="3"
                    >
                      <c-flex align="center">
                        <c-icon color="vc-orange.300" name="close" />
                        <c-text ml="3">Remove from list</c-text>
                      </c-flex>
                    </c-pseudo-box>
                  </c-list-item>

                  <c-list-item v-else>
                    <c-pseudo-box
                      fontSize="sm"
                      cursor="pointer"
                      @click="onSaveClick"
                      p="3"
                    >
                      <c-flex align="center">
                        <c-icon v-if="post.post_saves.length" color="vc-orange.300" name="close" />
                        <c-icon v-else color="vc-orange.300" name="bookmark" />
                        <c-text v-if="post.post_saves.length" ml="3">Remove from saved list</c-text>
                        <c-text v-else ml="3">Save this Post</c-text>
                      </c-flex>
                    </c-pseudo-box>
                  </c-list-item>

                  <c-list-item v-if="!isSavedPost">
                    <c-pseudo-box
                      fontSize="sm"
                      cursor="pointer"
                      @click="startPostEdit"
                      p="3"
                    >
                      <c-flex align="center">
                        <c-icon color="vc-orange.300" name="pen" />
                        <c-text ml="3">Edit Post</c-text>
                      </c-flex>
                    </c-pseudo-box>
                  </c-list-item>

                  <c-list-item v-if="!isSavedPost">
                    <c-pseudo-box
                      fontSize="sm"
                      cursor="pointer"
                      @click="confirmPostDelete"
                      p="3"
                    >
                      <c-flex align="center">
                        <c-icon color="vc-orange.300" name="trash" />
                        <c-text ml="3">Delete Post</c-text>
                      </c-flex>
                    </c-pseudo-box>
                  </c-list-item>
                  <c-list-item>
                    <c-pseudo-box
                      fontSize="sm"
                      cursor="pointer"
                      @click="getShareLink"
                      p="3"
                    >
                      <c-flex align="center">
                        <c-icon color="vc-orange.300" name="link" />
                        <c-text ml="3">Copy link to post</c-text>
                      </c-flex>
                    </c-pseudo-box>
                  </c-list-item>
                </c-list>
              </c-popover-body>
            </c-popover-content>
          </c-popover>
        </div>
      </c-flex>
    </c-flex>
    <!-- <c-flex align="center" mb="6">
      <template v-for="post_tag in post.post_tags">
        <c-flex
          color="blue.300"
          minWidth="120px"
          mr="5"
          mb="3"
          borderWidth="1px"
          borderRadius="4px"
          bg="blue.50"
          align="center"
          justify="center"
          h="30px"
          :key="post_tag.id"
          fontSize="xs"
          fontWeight="500"
        >
          {{ post_tag.tag.name }}
        </c-flex>
      </template>
    </c-flex> -->
    <c-text
      fontWeight="500"
      fontSize="15px"
      color="#000"
      mb="6"
      v-html="textOnlyContent"
      class="post__content"
    ></c-text>

      <!-- Optimized image loading -->
     <div
      :class="[
          'facebook-style-grid',
          imageSources.length > 5
            ? 'six-or-more'
            : `${changeNumToWord(imageSources.length)}-items`,
        ]"
     >
      <div
        class="grid-item"
        v-for="(img, index) in imageSources.slice(0, 5)"
        :key="index"
        :class="`item-${imageSources.length}`"
        @click.prevent="
          showImgModal({
            name: `item-${index}`,
            url: img,
            id: index,
            type: 'image/png'
          })"
      >
        <img 
          v-for="(image, index) in imageSources"
          :key="index"
          :src="image"
          :alt="`Image ${index + 1}`"       
        />
      </div>
     </div>
    <c-flex
      mb="6"
      v-if="!previewModal"
      @click="
        {
        }
      "
    >
      <div
        :class="[
          'facebook-style-grid',
          post.attachments.length > 5
            ? 'six-or-more'
            : `${changeNumToWord(post.attachments.length)}-items`,
        ]"
      >
        <div
          class="grid-item"
          v-for="(attachment, index) in post.attachments.slice(0, 5)"
          :key="attachment.id"
          :class="`item-${post.attachments.length}`"
          @click.prevent="showImageModal(attachment)"
        >
          <!-- Image -->
          <img
            v-if="attachment.type && attachment.type.startsWith('image')"
            :src="attachment.url"
            :alt="`Image ${index + 1}`"
            class="image"
            loading="lazy"
          />
          <!-- Video -->
          <video
            v-else-if="attachment.type && attachment.type.startsWith('video')"
            controls
            class="video"
            :data-src="attachment.url"
            v-lazy-load-video
          >
            Your browser does not support the video tag.
          </video>

          <!-- File -->
          <div v-else class="file-wrapper" v-lazy-load-attachment>
            <div class="file-icon">
              <c-icon
                :name="getFileIcon(attachment.type)"
                :color="getFileIconColor(attachment.type)"
                size="16"
              />
            </div>
            <div class="file-details">
              <c-text class="file-name">{{
                getFileName(attachment.url)
              }}</c-text>
            </div>
          </div>
          <div
            v-if="post.attachments.length > 5 && index === 4"
            class="overlay"
          >
            <span>+{{ post.attachments.length - 5 }}</span>
          </div>
        </div>
      </div>
    </c-flex>
    <template v-if="post.eventId">
      <c-pseudo-box @click="moveToEvent" v-if="event">
        <!-- <c-text mb="5">{{ event.description }}</c-text> -->
        <c-box borderRadius="8px" bg="white" mb="20px">
          <c-image
            h="250px"
            borderRadius="8px"
            w="100%"
            :src="
              event.thumb_nail
                ? event.thumb_nail
                : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRFo4PR9dPR7LmV1CqeEB6F5Z3jikCg8xBBA&usqp=CAU'
            "
            alt="Event thumbnail"
          />
          <c-flex justify="space-between" align="center" :p="[2, 3, 3]">
            <div>
              <c-text style="color: orangered" fontSize="xs"
                >{{ event.start_date }}, {{ event.start_time
                }}{{ event.timezone }}</c-text
              >
              <h3 class="text-bold">{{ event.name }}</h3>
              <c-text fontSize="sm">{{ event.company }}</c-text>
              <c-flex mt="2" justify="start" align="center">
                <c-icon
                  v-if="event.type === 'VIRTUAL'"
                  size="14px"
                  name="video"
                />
                <c-icon v-else size="14px" name="users" />
                <c-text ml="2" :fontSize="['10px', '12px', '14px']" fontWeight="500">{{
                  event.type === 'VIRTUAL' ? 'Online' : 'In person'
                }}</c-text>
              </c-flex>
            </div>
            <c-icon-button
              v-if="isMobile"
              size="xs"
              variant="outline"
              color="tomato"
              icon="eye"
            />
            <c-button
              v-else
              variant="outline"
              variant-color="vc-orange"
              @click="$router.push(`/app/events/${event.id}`)"
              size="xs"
              px="2"
              class="hover_btn"
              >View event</c-button
            >
          </c-flex>
        </c-box>
      </c-pseudo-box>
      <c-flex justify="center" align="center">
        <c-spinner
          v-if="isSavingComment"
          color="blue.400"
          thickness="2px"
        ></c-spinner>
      </c-flex>
    </template>
    <c-grid
      v-if="urls.length && !isVideo"
      :template-columns="urls.length === 1 ? `repeat(1, 1fr)` : `repeat(2, 1fr)`"
      gap="8"
      mb="2"
    >
      <c-link
        display="flex"
        borderWidth="1px"
        borderRadius="6px"
        :height="urls.length === 1 ? `150px` : `120px`"
        :href="url.url"
        v-for="url in urls"
        :key="url.id"
        is-external
        overflow="hidden"
      >
        <c-box
          backgroundSize="cover"
          backgroundPosition="top center"
          :style="{ backgroundImage: `url('${url.image}')` }"
          w="40%"
          h="100%"
        ></c-box>
        <c-box p="4" maxWidth="60%">
          <c-stack :spacing="2">
            <c-text fontSize="xs" fontWeight="600"> {{ url.title }} </c-text>
            <c-text
                fontSize="xs"
                fontWeight="500"
                color="gray.400"
                textOverflow="ellipsis"
              >
                {{ url.description || url['og:description'] }}
              </c-text>
           <c-link display="flex" fontSize="xs" align="center">
                <svg
                  v-chakra="{
                    fill: 'black',
                    w: '12px',
                    h: '12px',
                    mr: '2',
                  }"
                >
                  <use href="@/assets/icons/icon-link.svg#link"></use>
                </svg>
                {{ url.url }}
              </c-link> 
          </c-stack>
        </c-box>
      </c-link>
    </c-grid>
    <c-flex borderRadius="8px" bg="vc-gray.20" py="2" px="4" mb="2">
      <c-pseudo-box
        :_hover="{
          textDecoration: 'none',
          cursor: 'pointer',
        }"
        display="flex"
        @click="togglePostLike"
        mr="12"
        alignItems="center"
      >
        <svg
          v-chakra="{
            fill: 'gray.400',
            w: '4',
            h: '4',
            mr: '2',
          }"
          v-if="!isLikedPost"
        >
          <use href="@/assets/icons/icon-heart.svg#heart"></use>
        </svg>
        <svg
          v-else
          v-chakra="{
            w: '4',
            h: '4',
            mr: '2',
          }"
        >
          <use href="@/assets/icons/icon-heart-red.svg#heart"></use>
        </svg>
        <c-text :color="isLikedPost ? 'red.300' : 'gray.400'" fontSize="sm">
          {{ postLikes.length }}
        </c-text>
      </c-pseudo-box>
      <c-pseudo-box
        :_hover="{
          textDecoration: 'none',
          cursor: 'pointer',
        }"
        display="flex"
        mr="12"
        alignItems="center"
        @click="isCommentBoxVisible = !isCommentBoxVisible"
      >
        <svg
          v-chakra="{
            fill: 'gray.400',
            w: '4',
            h: '4',
            mr: '2',
          }"
        >
          <use href="@/assets/icons/icon-chat.svg#chat"></use>
        </svg>
        <c-text color="gray.400" fontSize="sm">
          {{ post.post_comments.length }}
        </c-text>
      </c-pseudo-box>
      <c-pseudo-box
        :_hover="{
          textDecoration: 'none',
          cursor: 'pointer',
        }"
        display="flex"
        mr="4"
        alignItems="center"
        @click="onShareClick(post)"
      >
        <svg v-chakra fill="gray.400" w="4" h="4" ml="1">
          <use href="@/assets/icons/share-box-fill.svg#share"></use>
        </svg>
      </c-pseudo-box>
    </c-flex>
    <transition name="fade">
      <c-flex
        px="6"
        py="3"
        align="center"
        minHeight="80px"
        borderTopWidth="1px"
        mt="3"
        mx="-1.5em"
        v-show="isCommentBoxVisible || isEditMode"
      >
        <c-avatar
          :name="user.firstname + ' ' + user.lastname"
          :src="user.profilePhoto"
          mr="3"
        />
        <c-flex
          align="center"
          flexBasis="calc(100% - 65px)"
          flexGrow="0"
          borderWidth="1px"
          borderRadius="4px"
          maxWidth="calc(100% - 65px)"
        >
          <c-box
            flexBasis="calc(100% - 40px)"
            flexGrow="0"
            minHeight="40px"
            maxWidth="calc(100% - 40px)"
          >
            <quill-editor
              class="editor"
              ref="textEditor"
              v-model="newComment"
              @change="onChange"
              :options="editorOptions"
            />
            <c-button
              px="0"
              border="none"
              :disabled="!newComment || !newComment.trim()"
              display="flex"
              justifyContent="center"
              variant="outline"
              @click="submitComment"
            >
              <c-spinner
                v-if="isSavingComment"
                color="blue.400"
                thickness="2px"
              ></c-spinner>
              <svg
                v-else
                v-chakra="{
                  fill: 'gray.400',
                  w: '16px',
                  h: '16px',
                }"
              >
                <use href="@/assets/icons/icon-send-button.svg#send"></use>
              </svg>
            </c-button>
          </c-box>
        </c-flex>
      </c-flex>
    </transition>
    <c-box px="6" py="5" v-if="postComments.length" bg="gray.100">
      <c-stack :spacing="4">
        <template v-for="(comment, index) in postComments">
          <c-box :key="comment.id">
            <Comment
              :comment="comment"
              v-show="isAllCommentsShown || index == 0"
              @editComment="onEditComment(comment)"
              @deleteComment="onDeleteComment(comment)"
            />
          </c-box>
        </template>
      </c-stack>
      <c-box mt="2" v-if="postComments.length > 1">
        <c-link
          @click="isAllCommentsShown = !isAllCommentsShown"
          fontWeight="600"
          fontSize="sm"
        >
          Show {{ isAllCommentsShown ? 'less' : 'more comments' }}
        </c-link>
      </c-box>
    </c-box>
    <c-alert-dialog
      :is-open="isDeleteDialogOpen"
      :least-destructive-ref="$refs.cancelRef"
      :on-close="closeDeleteDialog"
    >
      <c-alert-dialog-overlay />
      <c-alert-dialog-content>
        <c-alert-dialog-header font-size="lg" font-weight="bold">
          Delete Comment
        </c-alert-dialog-header>
        <c-alert-dialog-body>
          Are you sure? You can't undo this action afterwards.
        </c-alert-dialog-body>
        <c-alert-dialog-footer>
          <c-button ref="cancelRef" @click="closeDeleteDialog">
            Cancel
          </c-button>
          <c-button variantColor="red" @click="deleteComment" ml="3">
            Delete
            <c-spinner
              ml="3"
              v-if="isDeletingComment"
              color="#fff"
              thickness="2px"
            />
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
    <share-content
      :isShareOpen="isShareOpen"
      @close="onShareClick"
      :url="`explore/post/${postInAction.id}`"
      shareTitle="Share Post"
      :postTitle="'Post on VibrantFounder'"
      :postDescription="'Hey! I just shared a new post on VibrantFounder, check it out'"
    />
  </c-box>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapState, mapGetters } from 'vuex';
import {
  addPostComment,
  addPostLike,
  deletePost,
  deletePostLike,
  getMatchingTags,
  getMatchingUsers,
  updatePostComment,
} from '@/services/insight';
import Vue from 'vue';
import debounce from 'lodash.debounce';

import Comment from './Comment.vue';
import { generateID } from '@/helpers/data';

import { Quill } from 'vue-quill-editor';

import 'quill-mention/dist/quill.mention.min.css';

import 'quill-mention';

import '@/helpers/editor/blots/mention';
import { getUrlMetadata } from '@/services/common';
import ShareContent from './../../components/ShareContent.vue';
import { getInitials } from '../../../../helpers/utils';
import { getEventById } from '@/services/event';
import { EventBus } from '@/eventBus';
import DOMPurify from 'dompurify';
import {unFollowUser} from "@/services/user";
import {unFollowCompany} from "@/services/company";

Vue.directive('lazy-load-video', {
  inserted(el) {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const video = entry.target;
          video.src = video.dataset.src;
          video.load();
          observer.unobserve(video);
        }
      });
    }, {
      rootMargin: '0px',
      threshold: 0.1
    });

    observer.observe(el);
  }
});

Vue.directive('lazy-load-attachment', {
  inserted(el) {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const attachment = entry.target;
          // Load the attachment content here
          observer.unobserve(attachment);
        }
      });
    }, {
      rootMargin: '0px',
      threshold: 0.1
    });

    observer.observe(el);
  }
});

export default {
  components: {
    Comment,
    ShareContent,
  },
  props: {
    initialLoadDelay: {
      type: Number,
      default: 300
    },
    isEditedPost: {
      type: Boolean,
      default: false,
    },
    isSavedPost: {
      type: Boolean,
      default: false,
    },
    post: {
      type: Object,
      default: () => ({}),
    },
    previewModal: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    isPostDetailPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      contentLoaded: false,
      images: [],
      placeholderImage: "https://via.placeholder.com/300", // Temporary image while loading
      postContentReady: false,
      textOnlyContent: "",
      imageSources: [],
      loadedImages: 0,
      isVideo: false,
      currentIndex: 0,
      isImagesModalOpen: false,
      urls: [],
      postLikes: [],
      postComments: [],
      newComment: null,
      isImagePreviewModalOpen: false,
      isImgPreviewModalOpen: false,
      isShareOpen: false,
      imageInPreview: '',
      event: null,
      editorOptions: {
        placeholder: `Comment`,
        modules: {
          toolbar: false,
          clipboard: {
            matchers: [[Node.TEXT_NODE, this.linkMatcher]],
          },
          mention: {
            maxChars: 31,
            isolateCharacter: true,
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@', '#'],
            blotName: 'vc-mention',
            source: async (searchTerm, renderList, mentionChar) => {
              try {
                let matches = [];
                if (mentionChar === '@') {
                  matches = await this.getUserMatches(searchTerm);
                } else {
                  matches = await this.getTagMatches(searchTerm);
                }
                renderList(matches, searchTerm);
              } catch (e) {
                console.log({ e });
                renderList([], searchTerm);
              }
            },
          },
        },
      },
      isCommentBoxVisible: false,
      isAllCommentsShown: false,
      isSavingComment: false,
      isEditMode: false,
      editingComment: null,
      isDeleteDialogOpen: false,
      commentToDelete: null,
      isDeletingComment: false,
      postInAction: {},
      currentIndex1: 0,
      isMobile: false
    };
  },
  computed: {
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['isCompanyAdmin', 'isCompanyOwner']),
    contentRef() {
      return this.$refs.contentRef
    },
    pageLink() {
      return `${location.origin}/app/`;
    },
    isClickable() {
      return this.post.eventId;
    },
    isPostCreator() {
      return this.user.id === this.post.userId;
    },
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    isLikedPost() {
      return this.postLikes.some(
        (postLike) => postLike.user.id == this.user.id
      );
    },
    isMyPost() {
      return this.user.id === this.post.userId;
    },
    editor() {
      return this.$refs.textEditor;
    },
    isStaging() {
      return (
        window.location.hostname.startsWith('develop') ||
        window.location.hostname.startsWith('localhost')
      );
    },
    parsedPostContent() {
      return this.embedPreviews(this.post.content);
    },
  },
  mounted(){
    // this.initializeLazyLoading();
    this.processPostContent();
  },
  created() {
    this.checkIfMobile();
    this.getUrls();
    this.postLikes = cloneDeep(this.post.post_likes);
    this.postComments = cloneDeep(this.post.post_comments);
    if (this.post.eventId) {
      this.getEvent();
    }
  },
  methods: {
    checkIfMobile() {
      // Check if the screen width is less than 768px (mobile)
      this.isMobile = window.innerWidth < 768;
    },
    processPostContent() {
      // Extract images
      const content = this.post.content || "";
      this.imageSources = (content.match(/<img[^>]+src="([^"]+)"[^>]*>/g) || [])
        .map((imgTag) => {
          const match = imgTag.match(/src="([^"]+)"/);
          return match ? match[1] : null;
        })
        .filter((src) => src !== null);

      console.log("Images =>", this.imageSources);

      // Remove images from content
      this.textOnlyContent = this.embedPreviews(
        content.replace(/<img[^>]*>/g, '')
      );

      // Mark post as ready
      this.postContentReady = true;
    },

    onImageLoad() {
      console.log("Image loaded");
    },
    loadContent: debounce(function() {
      // Parse images from content
      const imgTags = this.post.content.match(/<img[^>]+src="([^">]+)/g)
      
      if (imgTags) {
        const srcList = imgTags.map(img => 
          /src="([^"]+)/.exec(img)[1]
        )
        
        this.images = srcList.map(src => ({
          src,
          loaded: false
        }))
      }

      // Load content HTML
      if (this.contentRef) {
        this.contentRef.innerHTML = this.post.content
      }

      this.isLoading = false
      setTimeout(() => {
        this.contentLoaded = true
      }, this.initialLoadDelay)
    }, 300),

    handleImageLoad(index) {
      // this.images[index].loaded = true
      console.log(index)
      this.loadedImages++

      if (this.loadedImages === this.images.length) {
        // All images loaded
        console.log('All images loaded')
      }
    },
    initializeLazyLoading() {
      const lazyImages = document.querySelectorAll('.lazy-image');

      const lazyImageObserver = new IntersectionObserver((entries, observer) => {
        console.log(observer)
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;
            lazyImage.src = lazyImage.dataset.src; // Load the image
            lazyImage.classList.add('loaded'); // Add the 'loaded' class
            lazyImageObserver.unobserve(lazyImage); // Stop observing once loaded
          }
        });
      });

      lazyImages.forEach((lazyImage) => {
        lazyImageObserver.observe(lazyImage);
      });
    },
    showImageModal(attached){
      console.log("Shexy=>", attached);
      const isFile =
        attached.type === 'application/pdf' ||
        attached.type.includes('word') ||
        attached.type.includes('excel') ||
        attached.type === 'text/csv' ||
        attached.type.includes(
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) ||
        attached.type.startsWith('application/vnd.ms-excel');
      this.isImagePreviewModalOpen = !isFile && true;
    },
    showImgModal(att){
      console.log(att)
      this.isImgPreviewModalOpen = true;
    },
    getFileIcon(type) {
      if (type === "application/pdf") return "file-pdf";
      if (type.includes("word")) return "file-word";
      if (type.includes("excel") || type === "text/csv" || type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || type.startsWith('application/vnd.ms-excel')) return "file-excel";
      return "file";
    },
    getFileIconColor(type) {
      if (type === "application/pdf") return "red.500";
      if (type.includes("word")) return "blue.500";
      if (type.includes("excel") || type === "text/csv") return "green.500";
      return "gray.500";
    },
    getMediaClass(index, totalAttachments) {
      if (totalAttachments === 1) return "full-width";
      if (totalAttachments === 2) return "half-width";
      if (totalAttachments === 3) return index === 0 ? "large-left" : "small-right";
      if (totalAttachments >= 4) return "grid-item";
    },
    getFileName(url) {
      return url.split("/").pop();
    },
    async Unfollow() {
      if(this.post.isCompanyPost){
       try{
         const response = await unFollowCompany({companyId: this.post.companyId, userId: this.user.id})
         if(response.data){
           this.$toast({
             title: 'Unfollowed this company',
             description: `You have successfully unfollowed this company`,
             status: 'success',
             position: 'top',
             duration: 3000,
           });
         }

       }catch (e) {
         console.error(e)
         this.$toast({
           title: 'Could not unfollow this company. Please try again.',
           description: ``,
           status: 'error',
           position: 'top',
           duration: 3000,
         });
       }
      } else {
        try {
          const resp = await unFollowUser(this.post.user.id, this.user.id)
          if(resp.data){
            this.$toast({
              title: 'Unfollowed this user',
              description: `You have successfully unfollowed this user`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          }
        } catch (e) {
          console.error(e)
        }
      }
    },
    async getShareLink() {
      let link = '';
      console.log(link, 'Gexing==>');
      let url = `explore/post/${this.post.id}`;
      link = `${this.pageLink}${url}`;

      await navigator.clipboard.writeText(link);
      this.$toast({
        title: 'Link copied to clipboard',
        description: ``,
        status: 'success',
        position: 'top',
        duration: 3000,
      });
    },

    embedPreviews(content) {
      // Regex to match anchor tags wrapping URLs
      const anchorTagPattern = /<a href="([^"]+)"[^>]*>(.*?)<\/a>/gi;
      // Regex to match image URLs directly
      const imgUrlPattern = /(https?:\/\/[^\s]+?\.(?:jpg|jpeg|png|gif|bmp|webp))/gi;

      const alreadyReplaced = new Set();

      // First, handle image URLs directly in the content
      content = content.replace(imgUrlPattern, (match, imgUrl) => {
        if (alreadyReplaced.has(imgUrl)) {
          return match; // Skip already processed image URLs
        }

        alreadyReplaced.add(imgUrl);

        // Replace the image URL with a lazy-loaded <img> tag
        return `
          <img 
            data-src="${imgUrl}" 
            alt="Embedded image" 
            class="lazy-image" 
            style="max-width: 100%; height: auto; border-radius: 8px;" 
            loading="lazy"
          />
        `;
      });

      // Then, handle anchor tags wrapping URLs
      content = content.replace(anchorTagPattern, (match, url, linkText) => {
        // Sanitize the URL
        url = this.sanitizeUrl(url);

        if (alreadyReplaced.has(url)) {
          return match; // Skip already processed URLs
        }

        alreadyReplaced.add(url);

        // Check if the URL is an image
        if (url.match(/\.(jpg|jpeg|png|gif|bmp|webp)$/i)) {
          // Replace the anchor tag with a lazy-loaded <img> tag
          return `
            <img 
              data-src="${url}" 
              alt="${linkText}" 
              class="lazy-image" 
              style="max-width: 100%; height: auto; border-radius: 8px;" 
              loading="lazy"
            />
          `;
        }

        // Check if the URL can be embedded (e.g., YouTube, Vimeo, etc.)
        const embedCode = this.getEmbedCode(url);

        // If embeddable, replace the anchor tag with the embed code
        if (embedCode) {
          return embedCode;
        }

        // If not embeddable, return the original anchor tag
        return match;
      });

      return content;
    },

    sanitizeUrl(url) {
      return DOMPurify.sanitize(url);
    },

    startPostEdit() {
      console.log('Fiickinly ==>', this.post);
      EventBus.$emit('open-post-modal', this.post);
    },

    getEmbedCode(url) {
      // Check for YouTube
      if (url.includes('youtube.com') || url.includes('youtu.be')) {
        this.isVideo = true;
        const videoId = this.extractYouTubeId(url);
        return videoId
          ? `<iframe 
              width="100%" 
              height="315" 
              src="https://www.youtube.com/embed/${videoId}" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen>
            </iframe>`
          : null;
      }

      // Check for Vimeo
      if (url.includes('vimeo.com')) {
        const videoId = url.split('/').pop();
        this.isVideo = true;
        return `<iframe src="https://player.vimeo.com/video/${videoId}" width="640" height="360" frameborder="0" allowfullscreen></iframe>`;
      }

      // Check for specific Facebook video URLs
      if (this.isFacebookVideo(url)) {
        if (url.includes('facebook.com/plugins/video.php')) {
          this.isVideo = true;
          // Direct iframe embed for Facebook
          return `<iframe src="${url}" width="560" height="315" frameborder="0" allowfullscreen></iframe>`;
        } else {
          // Convert Facebook video URL to iframe
          this.isVideo = false;
          return `<iframe src="https://www.facebook.com/plugins/video.php?href=${encodeURIComponent(
            url
          )}&show_text=0&width=560" width="560" height="315" frameborder="0" allowfullscreen></iframe>`;
        }
      }

      // Add more platforms that support embedding via iframe, such as Dailymotion, TikTok, etc.
      if (url.includes('dailymotion.com/video')) {
        const videoId = url.split('/').pop();
        this.isVideo = true;
        return `<iframe frameborder="0" width="560" height="315" src="https://www.dailymotion.com/embed/video/${videoId}" allowfullscreen></iframe>`;
      }

      if (url.includes('tiktok.com')) {
        this.isVideo = true;
        return `<iframe width="325" height="600" src="${url}" frameborder="0" allowfullscreen></iframe>`;
      }

      // Check for any generic iframe embeds (if the URL contains 'iframe')
      if (url.includes('iframe')) {
        this.isVideo = true;
        return `<iframe src="${url}" width="560" height="315" frameborder="0" allowfullscreen></iframe>`;
      }

      // No match for embedding, return null
      return null;
    },

    extractYouTubeId(url) {
      const match = url.match(
        /(?:youtube\.com\/.*v=|youtu\.be\/)([a-zA-Z0-9_-]+)/
      );
      return match ? match[1] : null;
    },

    isFacebookVideo(url) {
      // Check for Facebook Watch URLs
      if (url.match(/facebook\.com\/watch\/?\?v=\d+/)) {
        return true;
      }

      // Check for Facebook direct video URLs
      if (url.match(/facebook\.com\/.+\/videos\/\d+/)) {
        return true;
      }

      if (this.supportsOEmbed(url)) {
        return `${url}`;
      }

      // Not a recognized Facebook video URL
      return false;
    },
    supportsOEmbed(url) {
      // This is a placeholder; implement actual logic to determine if oEmbed is supported for the URL
      // Example: check for known oEmbed providers
      const oEmbedProviders = [
        'twitter.com',
        'instagram.com',
        'soundcloud.com',
        'slideshare.net',
      ];
      return oEmbedProviders.some((provider) => url.includes(provider));
    },
    // getOEmbedCode(url) {
    //   // Placeholder for oEmbed logic
    //   // Implement a service call to your backend that fetches oEmbed data and returns the HTML
    //   // Example:
    //   // return `<iframe src="${oEmbedUrl}" width="560" height="315" frameborder="0" allowfullscreen></iframe>`;
    //   return `<div>oEmbed preview for ${url}</div>`;
    // },
    moveToEvent() {
      if (this.post.eventId) {
        this.$router.push(`/app/events/${this.event.id}`);
      } else {
        return;
      }
    },
    hasValidMentionCharIndex(mentionCharIndex, text) {
      if (mentionCharIndex > -1) {
        if (
          !(mentionCharIndex === 0 || !!text[mentionCharIndex - 1].match(/\s/g))
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    async getUserMatches(searchTerm) {
      const res = await getMatchingUsers(`%${searchTerm}%`);
      const matches = res.data.user.map((user) => {
        return {
          id: user.id,
          value: `${user.firstname} ${user.lastname}`,
        };
      });
      return matches;
    },
    async getTagMatches(searchTerm) {
      const res = await getMatchingTags(`%${searchTerm}%`);
      const matches = res.data.tag.map((tag) => {
        return {
          id: tag.id,
          value: tag.name,
        };
      });
      return matches;
    },
    getCommentMentions() {
      const mentions = Array.from(
        this.$refs.textEditor.$el.querySelectorAll(
          '.mention[data-denotation-char="@"]'
        )
      ).map((el) => el.dataset);
      return mentions.map((mention) => {
        console.log('Frage!!!');
        console.log(mention);
        return {
          user: mention,
        };
      });
    },
    onSaveClick() {
      this.$emit('togglePostSave', { ...this.post });
    },

    onRemoveClick() {
      this.$emit('removeSavedPost', this.post);
    },

    confirmPostDelete() {
      this.$swal
        .fire({
          title:
            '<span style="font-family: Montserrat, serif, sans-serif">Are you sure?</span>',
          html: "<span style='font-family: Montserrat, sans-serif;'>You won't be able to revert this!</span>",
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#545457',
          confirmButtonColor: '#f8d9d9',
          confirmButtonText:
            '<span style="color: #F44336; font-weight: bold; font-family: Montserrat, serif, sans-serif">Yes, delete it!</span>',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.onDeleteClick();
            this.$swal.fire({
              title:
                '<span style="font-family: Montserrat, sans-serif">Deleted!</span>',
              html: '<span style="font-family: Montserrat, serif, sans-serif;">Post has been deleted successfully.</span>',
              icon: 'success',
            });
          }
        });
    },
    onDeleteClick() {
      // this.$emit('deletePost');
      deletePost(this.post.id)
        .then(() => {
          // this.$toast({
          //   title: 'Delete Successful.',
          //   description: `You have successfully deleted this post`,
          //   status: 'success',
          //   position: 'top',
          //   duration: 3000,
          // });
          // console.log(res)
          this.$emit('post-deleted');
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            title: 'Failed to Delete.',
            description: `There was an error while deleting this post`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    onEditComment(comment) {
      this.editingComment = comment;
      this.isEditMode = true;
      this.newComment = comment.content;
      this.$nextTick(() => {
        this.editor.setSelection(this.editor.getLength(), 0);
      });
    },
    async updateComment() {
      if (this.newComment === this.editingComment.content) {
        this.isEditMode = false;
        this.newComment = null;
        this.editingComment = null;
        return;
      }
      this.isSavingComment = true;
      const commentIndex = this.postComments.findIndex(
        (comment) => comment.id === this.editingComment.id
      );
      try {
        const res = await updatePostComment({
          id: this.editingComment.id,
          set: { isEdited: true, content: this.newComment },
        });
        this.postComments[commentIndex].content =
          res.data.update_post_comment_by_pk.content;
        this.postComments[commentIndex].isEdited = true;
        this.isSavingComment = false;
        this.isEditMode = false;
        this.newComment = null;
        this.editingComment = null;
      } catch (e) {
        console.log(e);
        this.$toast({
          title: 'An error occurred.',
          description: `Could not update post comment, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
        this.isSavingComment = false;
      }
    },
    onDeleteComment(comment) {
      this.isDeleteDialogOpen = true;
      this.commentToDelete = { ...comment };
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpen = false;
      this.commentToDelete = null;
    },
    async deleteComment() {
      this.isDeletingComment = true;
      try {
        await updatePostComment({
          id: this.commentToDelete.id,
          set: { isDeleted: true },
        });
        const commentIndex = this.postComments.findIndex(
          (comment) => comment.id === this.commentToDelete.id
        );
        this.postComments.splice(commentIndex, 1);
        this.closeDeleteDialog();
        this.$toast({
          title: 'Success!!!',
          description: `Comment has been deleted`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
        this.isDeletingComment = false;
      } catch (e) {
        this.isDeletingComment = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Could not delete post, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    nextSlide1() {
      this.currentIndex1 =
        (this.currentIndex1 + 1) % this.imageSources.length;
    },
    prevSlide1() {
      this.currentIndex1 =
        (this.currentIndex1 - 1 + this.imageSources.length) %
        this.imageSources.length;
    },
    nextSlide() {
      this.currentIndex =
        (this.currentIndex + 1) % this.post.attachments.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.post.attachments.length) %
        this.post.attachments.length;
    },
    closeImagePreviewModal() {
      this.isImagePreviewModalOpen = false;
    },
    closeImgPreviewModal() {
      this.isImgPreviewModalOpen = false;
    },

    getFileNameFromUrl(url) {
      // Create a new URL object
      const urlObj = new URL(url);

      // Get the pathname from the URL object
      const pathname = urlObj.pathname;

      // Split the pathname into parts using the '/' separator
      const pathParts = pathname.split('/');

      // The last element of the array should be the file name
      const fileName = pathParts[pathParts.length - 1];

      return fileName;
    },
    downloadFile(url) {
      // Open the file URL in a new window or tab
      window.open(url, '_blank');
    },
    getEvent() {
      this.isFetchingEvent = true;
      getEventById({
        id: this.post.eventId,
      })
        .then((res) => {
          this.event = res.data.event_by_pk;
          this.isFetchingEvent = false;
        })
        .catch((e) => {
          console.log(e);
          this.$toast({
            title: 'An error occurred.',
            description: `Could not fetch events, please try again`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    getInitials,
    onShareClick(post = {}) {
      this.isShareOpen = !this.isShareOpen;
      this.postInAction = post;
    },
    getInitialsColor(name) {
      const hash = this.hashCode(name);
      return `hsl(${hash % 360}, 60%, 50%)`; // Example using hue based on hash
    },
    urlify(text) {
      return text.replace(
        /^(?!href=").*(https?:\/\/[^\s]+)/g,
        '<a href="$1">$1</a>'
      );
      // or alternatively
      // return text.replace(urlRegex, '<a href="$1">$1</a>')
    },
    goToMentionProfile(mention) {
      console.log('Atongo ==>', mention);
      if (mention.companyId) {
        this.$router.push({
          name: 'CompanyProfile',
          params: {
            companyId: mention.company.id,
            type: 'company',
          },
        });
      } else {
        this.$router.push({
          name: 'Profile',
          params: {
            userId: mention.userId,
          },
        });
      }
    },

    goToProfile() {
      if (this.post.isCompanyPost) {
        this.$router.push({
          name: 'CompanyProfile',
          params: {
            companyId: this.post.companyId,
          },
        });
      } else {
        this.$router.push({
          name: 'Profile',
          params: {
            userId: this.post.userId,
          },
        });
      }
    },
    getUrls() {
      const urls = [
        ...new Set(
          this.post.content.match(
            /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi
          )
        ),
      ];
      if (!urls.length) return;
      urls.forEach(async (url) => {
        try {
          const res = await getUrlMetadata(url);
          this.urls.push({
            id: generateID(4),
            ...res.data,
          });
        } catch (e) {
          // do something
        }
      });
    },
    handleCloseImagePreview() {
      this.isImagePreviewModalOpen = false;
      this.imageInPreview = '';
    },
    async togglePostLike() {
      if (this.isLikedPost) {
        const postLike = this.postLikes.find(
          (like) => like.userId === this.user.id
        );
        const postLikeIndex = this.postLikes.findIndex(
          (like) => like.userId === this.user.id
        );
        try {
          this.postLikes.splice(postLikeIndex, 1);
          await deletePostLike(postLike.id);
        } catch (e) {
          this.postLikes.splice(postLikeIndex, 0, postLike);
          this.$toast({
            title: 'An error occurred.',
            description: `Could not unlike post, please try again`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      } else {
        const likeId = generateID(4);
        try {
          this.postLikes.push({
            id: likeId,
            user: { id: this.user.id },
          });
          const res = await addPostLike({
            postId: this.post.id,
          });
          const postLikeIndex = this.postLikes.findIndex(
            (like) => like.id === likeId
          );
          this.postLikes[postLikeIndex] = cloneDeep(
            res.data.insert_post_like_one
          );
        } catch (e) {
          const postLikeIndex = this.postLikes.findIndex(
            (like) => like.id === likeId
          );
          this.postLikes.splice(postLikeIndex, 1);
          this.$toast({
            title: 'An error occurred.',
            description: `Could not like post, please try again`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    handleCommentKeyUp(e) {
      if (e.key === 'Enter' || e.code === 'Enter') {
        this.addComment();
      }
    },
    submitComment() {
      console.log('Grex');
      if (this.isEditMode) {
        console.log('Rufus');
        this.updateComment();
      } else {
        console.log('Flexes');
        this.addComment();
      }
    },
    async addComment() {
      const {
        id: userId,
        email,
        profilePhoto,
        firstname,
        lastname,
      } = this.user;
      const data = {
        content: this.newComment,
        postId: this.post.id,
      };
      const commentId = generateID(4);
      this.postComments.push({
        id: commentId,
        postId: this.post.id,
        user: { id: userId, email, profilePhoto, firstname, lastname },
        content: this.newComment,
        replies: [],
        createdAt: new Date().toISOString(),
      });
      this.newComment = null;
      const commentIndex = this.postComments.findIndex(
        (comment) => comment.id == commentId
      );
      try {
        const res = await addPostComment(data);
        this.postComments[commentIndex] = cloneDeep(
          res.data.insert_post_comment_one
        );
      } catch (e) {
        this.postComments.splice(commentIndex, 1);
        this.$toast({
          title: 'An error occurred.',
          description: `Could not comment on post, please try again`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    setContentEmpty() {
      this.isContentEmpty = !this.editor.getText().trim();
    },
    linkMatcher(node, delta) {
      const regex = /https?:\/\/[^\s]+/g;
      if (typeof node.data !== 'string') return;
      const matches = node.data.match(regex);

      if (matches && matches.length > 0) {
        const ops = [];
        let str = node.data;
        matches.forEach(function (match) {
          const split = str.split(match);
          const beforeLink = split.shift();
          ops.push({ insert: beforeLink });
          ops.push({ insert: match, attributes: { link: match } });
          str = split.join(match);
        });
        ops.push({ insert: str });
        delta.ops = ops;
      }

      return delta;
    },
    getTextBeforeCursor(cursorPosition) {
      const startPos = Math.max(
        0,
        cursorPosition - this.editorOptions.modules.mention.maxChars
      );
      const textBeforeCursorPos = this.editor.getText(
        startPos,
        cursorPosition - startPos
      );
      return textBeforeCursorPos;
    },
    openImagePreviewModal(images) {
      const preview = this.$imagePreview({
        initIndex: 0,
        images: [...images],
      });

      preview();
    },
    onChange() {
      const range = this.editor.getSelection();
      if (range == null) return;
      const cursorPosition = range.index;
      const textBeforeCursor = this.getTextBeforeCursor(cursorPosition);
      const indexOfHash = textBeforeCursor.lastIndexOf('#');
      const mentionCharPos =
        cursorPosition - (textBeforeCursor.length - indexOfHash);
      if (indexOfHash > -1) {
        this.renderHashTag({
          textBeforeCursor,
          indexOfHash,
          cursorPosition,
          mentionCharPos,
        });
      }
      this.setContentEmpty();
    },
    renderHashTag({
      textBeforeCursor,
      indexOfHash,
      cursorPosition,
      mentionCharPos,
    }) {
      if (!this.hasValidMentionCharIndex(indexOfHash, textBeforeCursor)) {
        return;
      }
      const textAfter = textBeforeCursor.substring(indexOfHash + 1);
      if (/\s$/.test(textBeforeCursor) && textAfter.trim()) {
        //   this.editor.getFormat()
        this.editor.deleteText(
          mentionCharPos,
          cursorPosition - mentionCharPos,
          Quill.sources.USER
        );
        this.editor.insertEmbed(
          mentionCharPos,
          this.editorOptions.modules.mention.blotName,
          { value: textAfter.trim(), denotationChar: '#' },
          Quill.sources.USER
        );
        this.editor.insertText(mentionCharPos + 1, ' ', Quill.sources.USER);
        this.editor.setSelection(mentionCharPos + 2, Quill.sources.USER);
      }
    },

    changeNumToWord(fig){
      switch (fig) {
        case 2:
          return 'two';
        case 3:
          return 'three';
        case 4:
          return 'four';
        case 5:
          return 'five';
      }
    }
  },
};
</script>

<style lang="scss">
// .video-container {
//   position: relative;
//   padding-bottom: 56.25%;
//   height: 0;
//   overflow: hidden;
//   max-width: 100%;
//   background: #000;
//   margin-bottom: 20px;
// }

// .video-container iframe {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   border: 0;
// }

// .video-link {
//   margin-top: 10px;
//   text-align: center;
//   font-size: 14px;
// }

// .video-link a {
//   color: #0073e6;
//   text-decoration: none;
//   word-break: break-word;
// }

// .video-link a:hover {
//   text-decoration: underline;
// }

// .link-preview {
//   margin-top: 10px;
//   text-align: left;
//   font-size: 14px;
// }

// .link-preview a {
//   color: #0073e6;
//   text-decoration: none;
//   word-break: break-word;
// }

// .link-preview a:hover {
//   text-decoration: underline;
// }
// .image-grid-container {
// }

.skeleton-loader {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
}

.skeleton-title {
  height: 24px;
  width: 300px;
  background-color: #e0e0e0;
  margin-bottom: 16px;
  border-radius: 4px;
}

.skeleton-content {
  height: 100px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.lazy-image {
  max-width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.lazy-image.loaded {
  opacity: 1;
}

.content-section {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease-in-out;
}

.content-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.file-icon {
  margin-bottom: 8px;
}

.file-name {
  font-size: 0.875rem;
  font-weight: bold;
  word-break: break-word;
}

.image,
.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.file-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 100%;
  text-align: center;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.facebook-style-grid {
  display: grid;
  gap: 10px;
  width: 100%;
}

.facebook-style-grid.one-items {
  grid-template-columns: repeat(1, 1fr);
}

.facebook-style-grid.two-items {
  grid-template-columns: repeat(2, 2fr);
}

.facebook-style-grid.three-items {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: auto;
}
.facebook-style-grid.three-items > :nth-child(2n + 1):last-child {
  grid-column: span 2; /* If it's the only item in the last row, span full width */
}

.facebook-style-grid.four-items {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

.facebook-style-grid.five-items {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

.facebook-style-grid.five-items > :nth-child(2n + 1):last-child {
  grid-column: span 2; /* If it's the only item in the last row, span full width */
}


.facebook-style-grid.six-or-more {
  grid-template-columns: repeat(3, 1fr); /* Two columns */
  grid-auto-rows: auto; /* Rows adjust based on content */
  gap: 5px; /* Spacing between items */
}

.facebook-style-grid.six-or-more > :nth-child(2n + 1):last-child {
  grid-column: span 2; /* If it's the only item in the last row, span full width */
}


.grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  max-height: 320px;
}

.grid-item img,
.grid-item video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

/* Overlay for the "+n" text */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  z-index: 1;
  border-radius: 8px;
}

.document_name {
  height: 24px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.lazy-image {
  background-color: #f0f0f0; /* Placeholder background color */
  border-radius: 8px;
  display: block;
  width: 100%;
  height: auto;
  opacity: 0; /* Hide the image until it's loaded */
  transition: opacity 0.3s ease;
}

.lazy-image.loaded {
  opacity: 1; /* Show the image when it's loaded */
}

.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slider-content {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid {
  display: grid;
  background-size: cover;
  width: 100%;
  height: 250px;
  grid-template-columns: 1fr;
  grid-column-gap: 5px;
}

.css-1ixr94k {
  height: 500px !important;
}

// .grid-item {
//   flex: 1;
//   padding: 0;
//   min-width: 180px;
//   min-height: 180px;
//   border: none;
//   border-radius: 4px;
// }

@media screen and (max-width: 768px) {
  .grid-item {
    flex-basis: calc(100% - 10px); /* Full width on smaller screens */
  }
}
.pdf-icon {
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  background-color: #fff;
}
.pdf-icon:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.pdf-icon i {
  color: #d9534f;
  font-size: 36px;
}
.attachment-wrapper {
  display: grid;
  grid-auto-columns: minmax(
    200px,
    1fr
  ); /* Set minimum and maximum column width */
  grid-gap: 10px; /* Optional spacing */
  align-items: center;
  justify-content: space-between;
}

.attachment-wrapper.even .attachment-item {
  margin-right: 15px;
}

.attachment-wrapper.odd .attachment-item {
  margin-left: 15px;
}

@media (max-width: 600px) {
  .attachment-wrapper {
    flex-direction: column;
  }

  .attachment-wrapper.even .attachment-item,
  .attachment-wrapper.odd .attachment-item {
    margin: 0;
  }
}
.mention {
  background-color: transparent;
  @apply text-blue-500 font-semibold;
}
.post {
  &__content {
    a {
      pointer-events: none
    }
  }
}
.clickable {
  cursor: pointer;
}

.not-clickable {
}
</style>

<style lang="scss" scoped>
.editor {
  @apply max-w-full;
  ::v-deep {
    .ql-container {
      border-width: 0px;
      font-size: inherit;
      font-family: inherit;
      height: 60px;
    }
    .mention {
      @apply font-bold bg-transparent;
    }
    .ql-mention-list {
      @apply overflow-y-scroll;
      max-height: 300px;
    }
  }
}
</style>
